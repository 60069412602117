import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function DyFetch() {
    const [info, setInfo] = useState('');

    useEffect(() => {
        axios.get('http://localhost:5000/proxy/startpoint')
          .then(res => {
            setInfo(res.data);
          })
          .catch(error => {
            console.error(error);
          });
      }, []);


    return (
        <>
            <div className="App">
                <h1>React and Node.js Integration</h1>
                {/* <p>Message from the server: {JSON.stringify(data, null, 2)}</p> */}
                <p>Message from the server: {info}</p>
                <p>Message from the server: {JSON.stringify(info, null, 2)}</p>
            </div>
        </>
    )
}



