import React from 'react'
import { Link } from 'react-router-dom'
import './NavBar.css'

export default function Brands() {
  return (
   <>
   <div className='container'>
    <div className='row d-flex justify-content-evenly mt-3'>

          <div className='col'>
            <div className="d-flex flex-column mb-3">
            <div class="p-2 d-flex justify-content-evenly"><Link to={'/jaq'}> < img src='https://media.istockphoto.com/id/1408740166/photo/contemporary-bathroom-design-with-freestanding-bathtub-and-shower-stall.jpg?s=612x612&w=0&k=20&c=lyW-AAq_Y7cNAwGrLEWMkzt0NKum75AVGG_Bd3ynwfA='  class="transition-image" alt='' style={{ height: "150px", width: "150px", boxShadow: "5px 5px 10px gray",padding: "5px" }}></img></Link></div>
            <div class="p-0" style={{ textAlign: "center" }}><Link to={'/jaq'} className="nav-link" ><b style={{fontSize:"20px"}}>Jaquar</b></Link></div>
            </div>
          </div>
          <div className='col'>
            <div className="d-flex flex-column mb-3">
              <div class="p-2 d-flex justify-content-evenly"><Link to={'/jaq'}><img src='https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI='  class="transition-image" alt='' style={{ height: "150px", width: "150px", boxShadow: "5px 5px 10px gray",padding: "5px" }}></img></Link></div>
              <div class="p-0" style={{ textAlign: "center" }}><Link to={'/jaq'} className="nav-link" style={{fontSize:"20px"}} ><b>Hindware</b></Link></div>
            </div>
          </div>
          <div className='col'>
            <div className="d-flex flex-column mb-3">
            <div class="p-2 d-flex justify-content-evenly"><Link to={'/jaq'}><img src='https://media.istockphoto.com/id/1174136051/photo/caucasian-girl-holding-a-shower-watering-can-jets-of-water-on-hand-background-beige-tile.jpg?s=612x612&w=0&k=20&c=eZG5VSK0WEQT0sqYj88Y2xBiWOe-yz4whri_-ImbOlE='  class="transition-image" alt='' style={{ height: "150px", width: "150px", boxShadow: "5px 5px 10px gray",padding: "5px" }}></img></Link></div>
            <div class="p-0" style={{ textAlign: "center" }}><Link to={'/jaq'} className="nav-link" ><b style={{fontSize:"20px"}} >AsianPaints</b></Link></div>
            </div>
          </div>
          <div className='col'>
            <div className="d-flex flex-column mb-3">
            <div class="p-2 d-flex justify-content-evenly"><Link to={'/jaq'}><img src='https://media.istockphoto.com/id/1430303030/photo/modern-and-minimal-design-of-cream-colored-bathroom-vanity-with-marble-counter-top-and-white.jpg?s=1024x1024&w=is&k=20&c=huWiQZ0Hc715YX10WLDlitZVZYkgUZB-Vrrtx-xMl6Q='  class="transition-image" alt='' style={{ height: "150px", width: "150px", boxShadow: "5px 5px 10px gray",padding: "5px" }}></img></Link></div>
            <div class="p-0" style={{ textAlign: "center" }}><Link to={'/jaq'} className="nav-link" ><b style={{fontSize:"20px"}} >Kohler</b ></Link></div>
            </div>
          </div>
          <div className='col'>
            <div className="d-flex flex-column mb-3">
            <div class="p-2 d-flex justify-content-evenly"><Link to={'/jaq'}><img src='https://media.istockphoto.com/id/170053762/photo/modern-designed-sink-and-stylish-tap.jpg?s=612x612&w=0&k=20&c=29iOp08H7sW6-fiGWsFTh2NedWBpnKNTD9oe8IKZhPc='  class="transition-image" alt='' style={{ height: "150px", width: "150px", boxShadow: "5px 5px 10px gray",padding: "5px" }}></img></Link></div>
            <div class="p-0" style={{ textAlign: "center" }}><Link to={'/jaq'} className="nav-link" ><b style={{fontSize:"20px"}} >Grohe</b></Link></div>
            </div>
          </div>
          <div className='col'>
            <div className="d-flex flex-column mb-3">
            <div class="p-2 d-flex justify-content-evenly"><Link to={'/jaq'}><img src='https://media.istockphoto.com/id/618635048/photo/make-up-kit.jpg?s=612x612&w=0&k=20&c=zbOa-hE03pvpNOSDWLVxpXEe8hknPmVJULOa-ZPqtFo='  class="transition-image" alt='' style={{ height: "150px", width: "150px", boxShadow: "5px 5px 10px gray",padding: "5px" }}></img></Link></div>
            <div class="p-0" style={{ textAlign: "center" }}><Link to={'/jaq'} className="nav-link" ><b style={{fontSize:"20px"}} >Roca</b></Link></div>
            </div>
          </div>
    </div>
   </div>
   </>
  )
}
