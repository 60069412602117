import React from 'react'

export default function Comparision() {
  return (
    <>
    <div className='container'>
        <div className='row'>
            <div className='col col-lg-12'>
                

            </div>

        </div>

    </div>
    </>
  )
}
