import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Appp from './Component/Appp';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Home from './Component/Home';
import Contact from './Component/Contact';
import './style.css'
import Nav from './Component/Nav';
import Apppp from './compo1/Apppp';
import Homee from './compo1/Home';
import Conta from './compo1/Conta';
import Fetch from './compo1/fetch';
import AboutUs from './compo1/AboutUs';
import Blog from './compo1/Blog';
import App from './component2/App';
import Login from './component2/Login';
import ProductDetail from './component2/ProductDetail';
import DyFetch from './compo1/DynFetch';
import PostFetch from './compo1/PostFetch';
import Jaquar from './component2/Jaquar';
import WasBasin from './component2/WasBasin';
import TapSlider from './component2/TapSlider';
import BasinProductDetails from './component2/BasinDetails';
import AddCart from './component2/AddCart';
import Comparision from './component2/Comparision';
import OTPDetails from './compo1/OTPDetails';
import FetchLoginDetail from './compo1/FetchLoginDetails';
import DemoFetchLoginDetail from './component2/DemoLoginFetch';


// const routes=createBrowserRouter([
//   {
//     path:"/",
//     element:<Appp/>,
//     children:[
//       {index:'true',element:<Appp/>},
//       {path:'/home',element:<Home/>},
//       {path:'/contact',element:<Contact/>}
//     ]
//   }
// ])

// const routes=createBrowserRouter([
//   {
//     path:"/",
//     element:<Apppp/>,
//     children:[
//       {index:'true',element:<Homee/>},
//       {path:'/home',element:<Homee/>},
//       {path:'/con',element:<Conta/>},
//       {path:'/fetch',element:<Fetch/>},
//       {path:'/about',element:<AboutUs/>},
//       {path:'/blog',element:<Blog/>}
//     ]
//   }
// ])

const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { index: 'true', element: <Homee /> },
      { path: '/home', element: <Homee /> },
      { path: '/con', element: <Conta /> },
      { path: '/fetch', element: <Fetch /> },
      { path: '/Dyfetch', element: <DyFetch/> },
      { path: '/about', element: <AboutUs /> },
      { path: '/blog', element: <Blog /> },
      { path: '/log', element: <Login/> },
      { path: '/Pdet', element: <ProductDetail/> },
      { path: '/Pfetch', element: <PostFetch/> },
      { path: '/jaq', element: <Jaquar/> },
      { path: '/washB', element: <WasBasin/> },
      { path: '/taps', element: <TapSlider/> },
      { path: '/Basindet', element: <BasinProductDetails/> },
      { path: '/cart', element: <AddCart/> },
      { path: '/compa', element: <Comparision/> },
      {path:'/fetch',element:<Fetch/>},
      {path:'/fetchLogin',element:<FetchLoginDetail/>},
      //{path:'/fetchLogin',element:<DemoFetchLoginDetail/>},

    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={routes} />
);


reportWebVitals();
