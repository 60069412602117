import React from 'react'
import './BasinDetail.css'

import SimiProducts from './SimiProducts'
import { Link } from 'react-router-dom'

export default function BasinProductDetails() {
    function myGreeting() {
        setTimeout((alert('tem added to cart sucessFully')),2000)
        // setTimeout((document.getElementById("demo").innerHTML = "Item added to cart!"),2000)
      }


    //   const myTimeout=() => setTimeout(myGreeting, 2000);
     
     
    //   setTimeout(() => {
    //     document.getElementById("demo").innerHTML = ""; // Clear the message
    // }, 5000);

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col col-lg-5 col-md-12 col-sm-12 justify-content-center'>

                        <div className='row'>
                            <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="https://media.istockphoto.com/id/2022421244/photo/top-view-of-stylish-hotel-bathroom-interior-with-double-sink-and-window.jpg?s=612x612&w=0&k=20&c=yJFOtRyQlGsPuu3JyuW5KkaIsgCqPws_a-Ed6t9ZFdg=" style={{ height: "400px", width: "100%", borderRadius: "10px" }} class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="https://media.istockphoto.com/id/2165203432/photo/top-view-of-luxury-hotel-bathroom-interior-with-tub-sink-and-panoramic-window.jpg?s=612x612&w=0&k=20&c=m8C9CCHoVQJlVwqvU1VFquCjFi7gXdoIAbv6phtp3bg=" style={{ height: "400px", width: "100%", borderRadius: "10px" }} class="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-item">
                                        <img src="https://media.istockphoto.com/id/2145550279/photo/white-bathroom-corner-with-tub-and-shower.jpg?s=612x612&w=0&k=20&c=pyq3ifQ2cuwImQDOAl_lKPUn0-q42l7_vpe1EWoacfs=" style={{ height: "400px", width: "100%", borderRadius: "10px" }} class="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>             {/* first row end here */}
                    {/* --------------------------------------------------------------------------- */}
                    <div className='row mt-4'>
                        <div className='d-flex justify-content-evenly'>
                        <button type="button" style={{height:"50px",width:"150px",marginRight:"5px"}} class="btn btn-warning" onclick={myGreeting}> <i class="bi bi-cart-fill"></i>ADD TO CART</button>

                        <button type="button" style={{height:"50px",width:"150px"}} class="btn btn-danger" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="bottom" data-bs-content="Bottom popover"><i class="bi bi-lightning-fill"></i>BUY NOW</button>
                       

                       
                        </div>
                        <p  style={{ color: "#388e3c" }} id='demo'></p>
                    </div>
                    {/* -------------------------------------------------------------------------------- */}
                        <div className='mt-3'>                 {/* second row start here */}
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <h4 style={{ fontFamily: "Poppins",color:"gray" }}>Description</h4>
                                        
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        <p style={{ fontFamily: "Poppins",color:"gray",margin:"1px" }}>Hand-Made,Handwoven & Hand-Tufted</p>
                                            <p style={{ fontFamily: "Poppins",color:"gray" ,margin:"1px" }}>Strap Material: Silicon</p>
                                            <p style={{ fontFamily: "Poppins",color:"gray" ,margin:"1px" }}>Color:Ivory bottle green</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <h4 style={{ fontFamily: "Poppins",color:"gray" }}>Specifications</h4>
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body" >
                                        <p style={{ fontFamily: "Poppins",color:"gray" }}>It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables.
                                        </p>
                                           
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>                {/* second row end here */}


                    </div>      {/* col 5 div end here*/}


                    {/* --------------------------------------------------------------------------------------------------------------------------------------------- */}
                    <div className='col col-lg-1'>
                        {/* spacing purpose */}
                    </div>             {/* col 1 div end here*/}



                    <div className='col col-lg-6'>
                        <h3 style={{ fontFamily: "Poppins"}}>haus & kinder Decorative Sham Throw Embroidered Wash Basins| 12inch x 18inch | Ceramic material and Glossy</h3><hr></hr>
                        <div className=' d-flex justify-content-start'>
                            <div className='d-flex justify-content-evenly' style={{ backgroundColor: "#388e3c", height: "25px", width: "50px", borderRadius: "5px" }}>&nbsp;4.2 <i class="bi bi-star-fill"></i></div>&nbsp; <span>2,35,028 Ratings & 16,756 Reviews</span>
                        </div>


                        <p style={{ color: "#388e3c" }}><b>Special Offers</b></p>
                        <div className=' d-flex justify-content-start'>
                            <h2><i class="bi bi-currency-rupee"></i>2,999</h2> &nbsp; &nbsp;
                            <h4 style={{ textDecoration: "line-through", color: "gray", marginTop: "3px" }}><i class="bi bi-currency-rupee"></i>4555</h4>&nbsp;&nbsp;
                            <p style={{ color: "#388e3c", marginTop: "5px" }}>72% off</p>

                        </div>
                        <p style={{ color: "gray", fontFamily: "Poppins", margin: "0" }}>Inclusive of all taxes</p>
                        <p style={{ color: "gray", fontFamily: "Poppins" }}>EMI starts at ₹213. No Cost EMI available</p><hr></hr>




                        <div className='mt-2'>                                 {/* extra col div start here*/}

                        </div> {/* extra col2 div end here*/}

                        <div>                                  {/* extra col3 div start here*/}

                            <ul style={{ color: "gray" }}>
                                <h4 style={{ margin: "0", color: "#8b9293 " }}>Product Details:</h4>
                                <li style={{ fontFamily: "Poppins" }}>Modern abstract design beautifully enhances any room of the home, from the living room, dining room, bedroom, entryway, nursery, dorm room, study, or home office</li>
                                <li style={{ fontFamily: "Poppins" }}>Modern abstract design beautifully enhances any room of the home, from the living room, dining room, bedroom, entryway, nursery, dorm room, study, or home office</li>
                                <li style={{ fontFamily: "Poppins" }}>Modern abstract design beautifully enhances any room of the home, from the living room, dining room, bedroom, entryway, nursery, dorm room, study, or home office</li>
                                <hr></hr>
                                <h4 style={{ margin: "0", color: "#8b9293 " }}>Product Dimensions:</h4>
                                <li style={{ fontFamily: "Poppins" ,fontSize:"18px"}}>Diverter Consisting of Operating
                                Lever, Cartridge Sleeve, Wall Flange</li>
                                <li style={{ fontFamily: "Poppins" ,fontSize:"18px"}}><li style={{ fontFamily: "Poppins" ,fontSize:"18px"}}>parts kit Consisting of Operating Lever Wall Flange & Button Only</li></li>

                            </ul>

                        </div>              {/* div col3 ends here*/}
                        <hr></hr>


                        <div className='container mt-4'>
        <div className='d-flex feature-container justify-content-evenly'>
            <div className='col-12 col-md-2 feature-col'>
                <h3><i className="bi bi-truck feature-icon"></i></h3>
                <div className="feature-text">Free Delivery</div>
            </div>
            <div className='col-12 col-md-2 feature-col'>
                <h3><i className="bi bi-cash-coin feature-icon"></i></h3>
                <div className="feature-text">Cash OD</div>
            </div>
            <div className='col-12 col-md-2 feature-col'>
                <h3><i className="bi bi-cup-hot feature-icon"></i></h3>
                <div className="feature-text">Top Brands</div>
            </div>
            <div className='col-12 col-md-2 feature-col'>
                <h3><i className="bi bi-alarm feature-icon"></i></h3>
                <div className="feature-text">Returnable</div>
            </div>
            <div className='col-12 col-md-2 feature-col'>
                <h3><i className="bi bi-truck feature-icon"></i></h3>
                <div className="feature-text">Free Delivery</div>
            </div>
        </div>
    </div>
    <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
    <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
                                               {/* extra col div dtart here*/}
                        <hr></hr>

                    </div>
                    {/* col 6 div end here*/}
                </div>             {/* col div end here*/}
            </div>          {/* container div end here*/}
            {/* <SimilarProducts/> */}
            <SimiProducts/>
            {/* <CardCarousel/> */}
        </>
    )
}









