import React from 'react'

export default function SimiProducts() {
  return (
    <div>
        <div className='container'>
            <div className='row mt-5'>
            <h2>Similar Products:</h2>

            <div id="carouselExampleControlsNoTouching" class="carousel slide" data-bs-touch="false" data-bs-interval="false">
  <div class="carousel-inner">
    <div class="carousel-item active">
        <div className='container'>
            <div className='row' >


            <div className='col-lg-3 col-md-6 mb-2'>
                        <div class="card" style={{ width: "100%", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/1945608943/photo/white-bathroom-modern-japan-minimal-style-3d-rendering.jpg?s=612x612&w=0&k=20&c=fgGtD-6nGBXPSgi_ioPLdsPVg05imLqKWSsYIXsvZZw=" class="card-img-top" alt="..."style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Tabletop Basin</h5>
                                <p class="card-text mt-0">Stock photos are same photographs that are licensed for commercial purposes..</p>
                                <p class="card-text" style={{margin:"2px"}}><b>Color:Indigo Blue</b></p>
                                
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:25000</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-6 mb-2'>
                        <div class="card" style={{ width: "100%", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/1945620081/photo/wooden-and-black-granite-wall-in-japan-bathroom-modern-onsen-minimal-style.jpg?s=612x612&w=0&k=20&c=XVa2pNgfA68JCjyR95szir4usXkIdwkB-Gw26C41A6o=" class="card-img-top" alt="..."style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Wall-Mounted</h5>
                                <p class="card-text mt-0">Stock photos are same photographs that are licensed for commercial purposes..</p>
                                <p class="card-text" style={{margin:"2px"}}><b>Color:Transque Yellow</b></p>
                                
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:25000</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-6 mb-2'>
                        <div class="card" style={{ width: "100%", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/1356431577/photo/close-up-of-sink-with-oval-mirror-standing-in-on-beige-wall.jpg?s=612x612&w=0&k=20&c=ZCjIKKTmU3PPa1lNsHnEvS3i2p-vvzFvuGpvdzgpTX4=" class="card-img-top" alt="..."style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Oval Shape</h5>
                                <p class="card-text mt-0">Stock photos are same photographs that are licensed for commercial purposes..</p>
                                <p class="card-text" style={{margin:"2px"}}><b>Color:creamy white</b></p>
                                
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:25000</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-6 mb-2'>
                        <div class="card" style={{ width: "100%", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/1282243489/photo/handmade-re-useable-makeup-remover-cloths-creams-in-recyclable-containers.jpg?s=612x612&w=0&k=20&c=X07-H5clPySO-4EDcU49xA0MIJwdb2XYn4aCTdclm1E=" class="card-img-top" alt="..."style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Square Basin</h5>
                                <p class="card-text mt-0">Stock photos are same photographs that are licensed for commercial purposes..</p>
                                <p class="card-text" style={{margin:"2px"}}><b>Color:Bottle Green</b></p>
                                
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:25000</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                
                            </div>
                        </div>
                    </div>
            </div>   {/* row div end here*/}
        </div>     {/* container div end here*/}


    </div>
    {/* ----------------------------------------------------------------------------------------- */}
    <div class="carousel-item">

    <div className='container'>
            <div className='row' >
                
            <div className='col-lg-3 col-md-6 mb-2'>
                        <div class="card" style={{ width: "100%", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/176803284/photo/modern-bathroom-with-brown-tile-glass-shower-tub.jpg?s=612x612&w=0&k=20&c=VbrUmCS_H2zSM1z_XaUp_7J4kpsgr4t5REt_lB7f7Oc=" class="card-img-top" alt="..."style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Tabletop Basin</h5>
                                <p class="card-text mt-0">Stock photos are same photographs that are licensed for commercial purposes..</p>
                                <p class="card-text" style={{margin:"2px"}}><b>Color:Dark Purple</b></p>
                                
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:25000</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-6 mb-2'>
                        <div class="card" style={{ width: "100%", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/1348250600/photo/bathroom-design-modern-classice-3d-rendering.jpg?s=612x612&w=0&k=20&c=mIFp3QlpT9eR1b6ln3jGBSsY5FIo1sO73cVc6sCMpr8=" class="card-img-top" alt="..."style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Under Mount</h5>
                                <p class="card-text mt-0">Stock photos are same photographs that are licensed for commercial purposes..</p>
                                <p class="card-text" style={{margin:"2px"}}><b>Color:Shinny red</b></p>
                                
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:25000</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-6 mb-2'>
                        <div class="card" style={{ width: "100%", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/1469412265/photo/modern-contemporary-loft-style-bathroom-with-tropical-style-nature-view-3d-render.jpg?s=612x612&w=0&k=20&c=-eEB32PdMdIlfA4Fu58t0pwRv-0H2K35AXR3e1RZ5Xo=" class="card-img-top" alt="..."style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Pedestal</h5>
                                <p class="card-text mt-0">Stock photos are same photographs that are licensed for commercial purposes..</p>
                                <p class="card-text" style={{margin:"2px"}}><b>Color:Stunning Pink</b></p>
                                
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:25000</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-6 mb-2'>
                        <div class="card" style={{ width: "100%", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/1536767484/photo/luxury-modern-bathroom.jpg?s=612x612&w=0&k=20&c=IK4OrsZwz3q7LUGCKVdfEDrMMco8_vcPgguCyO3LrY8=" class="card-img-top" alt="..."style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Drop-In</h5>
                                <p class="card-text mt-0">Stock photos are same photographs that are licensed for commercial purposes..</p>
                                <p class="card-text" style={{margin:"2px"}}><b>Color:Neavy blue</b></p>
                                
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:25000</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                
                            </div>
                        </div>
                    </div>
            </div>   {/* row div end here*/}
        </div>     {/* container div end here*/}

    </div>

    {/* ---------------------------------------------------------------------------- */}
    <div class="carousel-item">

    <div className='container'>
            <div className='row' >


            <div className='col-lg-3 col-md-6 mb-2'>
                        <div class="card" style={{ width: "100%", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/1490571634/photo/digitally-generated-image-of-a-luxury-bathroom-with-marble-tiles.jpg?s=612x612&w=0&k=20&c=BXrrnNdtpWdkzyQOlr-KvP-v9gTixxoXjEYpv28XHkc=" class="card-img-top" alt="..."style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Mat Finishing</h5>
                                <p class="card-text mt-0">Stock photos are same photographs that are licensed for commercial purposes..</p>
                                <p class="card-text" style={{margin:"2px"}}><b>Color:Transque Yellow</b></p>
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:25000</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-6 mb-2'>
                        <div class="card" style={{ width: "100%", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/2149389160/photo/row-of-wash-sink-in-public-toilet.jpg?s=612x612&w=0&k=20&c=FYbrcpIRW93qMj1aAoNcuwUsWWv-i88dNYAWUSi2ppg=" class="card-img-top" alt="..."style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>top Mounted</h5>
                                <p class="card-text mt-0">Stock photos are same photographs that are licensed for commercial purposes..</p>
                                <p class="card-text" style={{margin:"0"}}><b>Color:Glossy Black</b></p>
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:25000</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-6 mb-2'>
                        <div class="card" style={{ width: "100%", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/1084656062/photo/interior-of-a-hotel-bathroom.jpg?s=612x612&w=0&k=20&c=rZxxHZ_QxV4SZtNwi1izI1jKLckdS9Uz0LZc_M41_OE=" class="card-img-top" alt="..."style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Corner fitting</h5>
                                <p class="card-text mt-0">Stock photos are same photographs that are licensed for commercial purposes..</p>
                                <p class="card-text" style={{margin:"0"}}><b>Color:Sky Blue</b></p>
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:25000</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-6 mb-2'>
                        <div class="card" style={{ width: "100%", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/1287181967/photo/brown-bottle-mockup-for-cosmetic-products-and-various-bath-care-accessories.jpg?s=612x612&w=0&k=20&c=Q8sKZqccqVtQM3chD6mb82xRzD4IIylAn3GpfL-iSzI=" class="card-img-top" alt="..."style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Glossy Finish</h5>
                                <p class="card-text mt-0">Stock photos are same photographs that are licensed for commercial purposes..</p>
                                <p class="card-text" style={{margin:"0"}}><b>Color:Ivory Green</b></p>
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:2821</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                
                            </div>
                        </div>
                    </div>
            </div>   {/* row div end here*/}
        </div>     {/* container div end here*/}

{/* ------------------------------------------------------------------------------------------- */}
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button btn-success" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
   

    <span class="visually-hidden ">Next</span>
  </button>
</div>
            </div>       {/* row div end here*/}

        </div>
    </div>
  )
}











