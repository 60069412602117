import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function FetchLoginDetail() {
    const [record, setRecord] = useState(null); // Default to null to handle no data
    const [loading, setLoading] = useState(true); // Track loading state
   
    useEffect(() => {
        axios.post('http://localhost:5000/proxy/Loginpoint', {
            one: 'Mau0001',
            two: 'MI#Orfo$oo7',
            authApiKey: 'ytfkenaojjawmbjnbsyyj-vhvbs'
        })
        .then(res => {
            setRecord(res.data);
            setLoading(false);
           
        })
        .catch(error => {
            console.error('There was an error!', error);
            setLoading(false);
        });
    }, []);

    const userAddress = record && Array.isArray(record) && record.length > 0 ? record[0].user_address : '';
    const userName = record && Array.isArray(record) && record.length > 0 ? record[0].user_person_name : '';
    return (
        <div className="App container mt-4">
            <h1 className="text-center mb-4">React and Node.js Integration</h1>
            <p>message:{JSON.stringify(record, null, 2)}</p>
            <p className="text-center">
                Message from the server: {loading ? 'Loading...' : record ? JSON.stringify(record, null, 2) : 'No data available'}
            </p>
            <p className="text-center">
                {loading ? 'Loading...' : (userAddress ? `User Address: ${userAddress}` : 'No data available')}
            </p>
            <p className="text-center">
                {loading ? 'Loading...' : (userName ? `User Address: ${userName}` : 'No data available')}
            </p>
        </div>
    );
}
