import React from 'react'
import { Link } from 'react-router-dom'

export default function BasinCards2() {
    return (
        <>
            <div className='container'>
                <div className='row mt-4 d-flex justify-content-center'>
                <h3 style={{color:"#ff004f",textAlign:"center"}}>
                Other Wash Basin Products
                </h3>
                   
                    <div className='col col-lg-3 col-md-6 col-sm-12 mb-2'>
                        <div class="card" style={{ width: "17rem", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/1062255852/photo/modern-white-bathroom-sink-with-faucet.jpg?s=612x612&w=0&k=20&c=z2zzEyhmavFXzhQf54YCGdWC6a0GzQny4PPKRjCvB2U=" class="card-img-top" alt="..."style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Vignette Prime</h5>
               <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px",color:"#1593af"}}>Product Code: KUP-35233NKPM</p>

                                <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px"}}> <b>Product: </b>Exposed Part Kit of Concealed Stop Cock & Flush Cock with Fitting Sleeve wire.</p>
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:25000</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                <Link to={'/Basindet'} class="btn btn-outline-warning">View Product Detail</Link>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3  col-md-6 mb-2'>
                        <div class="card" style={{ width: "17rem", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/1396585161/photo/wash-basin-in-interior-of-bathroom.jpg?s=612x612&w=0&k=20&c=1TPlHzi7wxnRgXWDFbzyb6tkFke2kt2tdhXqsquoaGI=" class="card-img-top" alt="..." style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Faucets(Aria)</h5>
               <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px",color:"#1593af"}}>Product Code: KUP-35233NKPM</p>

                                <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px"}}> <b>Product: </b>Exposed Part Kit of Concealed Stop Cock & Flush Cock with Sleeve top fitted</p>
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:18,550</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                <Link to={'/Basindet'} class="btn btn-outline-warning">View Product Detail</Link>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-6 mb-2'>
                        <div class="card" style={{ width: "17rem", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/1356431577/photo/close-up-of-sink-with-oval-mirror-standing-in-on-beige-wall.jpg?s=612x612&w=0&k=20&c=ZCjIKKTmU3PPa1lNsHnEvS3i2p-vvzFvuGpvdzgpTX4=" class="card-img-top" alt="..." style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Florentine(Bidet)</h5>
               <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px",color:"#1593af"}}>Product Code: KUP-35233NKPM</p>

                                <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px"}}> <b>Product: </b>Exposed Part Kit of Concealed Stop Cock & Flush Cock with wall mounted disc</p>
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:21,500</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                <Link to={'/Basindet'} class="btn btn-outline-warning">View Product Detail</Link>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-6 mb-2'>
                        <div class="card" style={{ width: "17rem", boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
                            <img src="https://media.istockphoto.com/id/2119440067/photo/bathroom-sink-in-industrial-style-in-loft-apartment-simplicity-and-modern-design.jpg?s=612x612&w=0&k=20&c=8jKqGU8ezOoqinLt5dYe3wxmrbyq9-WpwfnoOE3vAjI=" class="card-img-top" alt="..." style={{height:"180px"}} />
                            <div class="card-body">
                                <h5 class="card-title " style={{color:"#ff004f"}}>Faucets(Accessories)</h5>
               <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px",color:"#1593af"}}>Product Code: KUP-35233NKPM</p>

                                <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px"}}> <b>Product: </b>Exposed Part Kit of Concealed Stop Cock & Flush Cock with Fitting Sleeve Stop</p>
                                <p class="card-text" style={{color:"#15a1c4"}}><b>Price:25,000</b></p>
                                {/* <p class="card-text" style={{color:"#a9b911"}}>Category:Electronics</p> */}
                                <Link to={'/Basindet'} class="btn btn-outline-warning">View Product Detail</Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
