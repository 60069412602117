import React from 'react'
import MenuBar from './MenuBar'
import WashBasinCard from './WashBasinCard'

export default function WasBasin() {
  return (
    <div>
<MenuBar/>
<WashBasinCard/>

    </div>
  )
}
