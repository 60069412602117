import React from 'react';
import './Slide.css'; // Ensure this file has the necessary styles
import MenuBar from './MenuBar';
import { Link } from 'react-router-dom';

export default function TapSlider() {
    
  return (
    <>
    <MenuBar/>

      <div className='container'>
        <div className='row mt-4'> 
          <div className='col'>
            <div className='card' style={{ width: '18rem', boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active dot-1">
      <img src="https://media.istockphoto.com/id/1454053508/photo/a-drop-of-water-drips-from-a-leaky-faucet.jpg?s=612x612&w=0&k=20&c=2uz6WUq0BAlcVZ4bu1Rqc1lIl0C4whiWYP9CBb7rK_Y=" class="d-block w-100" alt="..." style={{height:"200px"}}/>
    </div>
    <div class="carousel-item ">
      <img src="https://media.istockphoto.com/id/182691828/photo/bathroom-faucet.jpg?s=612x612&w=0&k=20&c=4erUeHXd06v50a9z6IPxx3LqG7oO6R2cig9hSaLPalI=" class="d-block w-100" alt="..." style={{height:"200px"}}/>
    </div>
    <div class="carousel-item">
      <img src="https://media.istockphoto.com/id/1466744580/photo/water-running-from-tap-into-kitchen-sink.jpg?s=612x612&w=0&k=20&c=BrsovpLActk_xt2rAJ_Bn9BzeyQskBAi4YEEkYnMpmE=" class="d-block w-100" alt="..." style={{height:"200px"}}/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next  " type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
              <div className="card-body">
               <h3 style={{ fontFamily: "Lucida Handwriting",color:"#ff004f"}}>Pillar taps</h3>
               <p  style={{ fontFamily: "Lucida Handwriting",color:"#FF5733"}}>Brands:Lotus,Jasmin</p>
                <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px"}}>Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <Link to={'/Basindet'}><a href="#" class="btn btn-outline-warning">View Product Detail</a></Link>
              </div>
            </div>
          </div>             {/* col div end here*/}

          <div className='col'>
            <div className='card' style={{ width: '18rem', boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
            <div id="carouselExampleControls2" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active dot-1">
      <img src="https://media.istockphoto.com/id/1204682124/photo/close-up-of-young-man-pouring-fresh-water-from-kitchen-sink-home-interior.jpg?s=612x612&w=0&k=20&c=QekETVO4fRKKN3OghEU5jxJ-FzmnMccd0Sq4Yky8_xs=" class="d-block w-100" alt="..." style={{height:"200px"}}/>
    </div>
    <div class="carousel-item ">
      <img src="https://media.istockphoto.com/id/1204637747/photo/filling-up-a-glass-with-drinking-water-from-kitchen-tap.jpg?s=612x612&w=0&k=20&c=VLVuO2fq_eyEU6ZFFOMo57EdUXDTs2vEH81bUL5ufJU=" class="d-block w-100" alt="..." style={{height:"200px"}}/>
    </div>
    <div class="carousel-item">
      <img src="https://media.istockphoto.com/id/513366678/photo/leaking-faucet-a-droplet-of-water.jpg?s=612x612&w=0&k=20&c=EU6Aags45-cgQvFXeNjFned83yWT32OSvjdDYMeh8TE=" class="d-block w-100" alt="..." style={{height:"200px"}}/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next  " type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
              <div className="card-body">
               <h3 style={{ fontFamily: "Lucida Handwriting",color:"#ff004f"}}> Monobloc taps</h3>
               <p  style={{ fontFamily: "Lucida Handwriting",color:"#FF5733"}}>Brands:Lotus,Jasmin</p>
                <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px"}}>Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <Link to={'/Basindet'}><a href="#" class="btn btn-outline-warning">View Product Detail</a></Link>
              </div>
            </div>
          </div>             {/* col div end here*/}

          <div className='col'>
            <div className='card' style={{ width: '18rem', boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
            <div id="carouselExampleInterval3" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active dot-1">
      <img src="https://media.istockphoto.com/id/855532396/photo/pouring-water-from-a-faucet.jpg?s=612x612&w=0&k=20&c=Fg4xr-zAj_YFNW0b8HB7WQYoKombJTteqsKRJAGslFE=" class="d-block w-100" alt="..." style={{height:"200px"}}/>
    </div>
    <div class="carousel-item ">
      <img src="https://media.istockphoto.com/id/1178151824/photo/turn-on-water-from-faucet.jpg?s=612x612&w=0&k=20&c=gIub_CiFKqkoGW8KJP8ELeKiD6UEKHZ_KNcuPxm18sE=" class="d-block w-100" alt="..." style={{height:"200px"}}/>
    </div>
    <div class="carousel-item">
      <img src="https://media.istockphoto.com/id/91156679/photo/close-up-of-modern-kitchen-faucet-and-sink.jpg?s=612x612&w=0&k=20&c=vDCYM2Nnx3nkp7NOv0xXsY1rtZJ_6ZbI0Ddf5bxL4ak=" class="d-block w-100" alt="..." style={{height:"200px"}}/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval3" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next  " type="button" data-bs-target="#carouselExampleInterval3" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
              <div className="card-body">
               <h3 style={{ fontFamily: "Lucida Handwriting",color:"#ff004f"}}>Disc taps</h3>
               <p  style={{ fontFamily: "Lucida Handwriting",color:"#FF5733"}}>Brands:Lotus,Jasmin</p>
                <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px"}}>Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <Link to={'/Basindet'}><a href="#" class="btn btn-outline-warning">View Product Detail</a></Link>
              </div>
            </div>
          </div>             {/* col div end here*/}
        </div>             {/* row div end here*/}
      </div>           {/* row div end here*/}
    </>
  );
}
