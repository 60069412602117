import React from 'react'
import './Footer.css'

export default function Footer() {
  return (
   <>
          <div style={{ height: "100px", backgroundColor: "#f5adc6 ", marginTop: "10px" }}>
              <div className='container' >
                  <div className='row mt-4' >
                    <h3 style={{textAlign:"center",marginTop:"30px",color:"gray",fontWeight:"600"}}>Trading Application </h3>
                      {/* <div className='col d-flex justify-content-evenly mt-4'>
                          <form class="d-flex" role="search">
                              <input class="form-control form-lg me-2" style={{width:"300px", height:"50px"}} type="email" placeholder="Enter Email Address" aria-label="Search" />
                              <button class="btn btn-outline-suceses btn-lg" style={{backgroundColor:"white"}} type="submit">Submit</button>
                          </form>
                      </div> */}
                  </div>
              </div>
          </div>



          <div style={{ backgroundColor: "#eeeded " }}>
              <div className='container info' >
                  <div className='row d-flex justify-content-center' >

                  <div className='col col-lg-12 col-sm-12 mt-1' style={{ marginLeft: "50px" }}>
    <p>Register Today, Earn Tomorrow</p>
    <p><i className="bi bi-geo-alt-fill"></i> 601, Express Chambers, Sir M.V. Road, Opp. Natraj by Rustomjee, Near WEH Metro Station, Andheri East, Mumbai – 400069.</p>
    <p><i className="bi bi-envelope"></i> sales@weareoneenterprises.com</p>
    <div className="d-flex justify-content-start align-items-center" style={{ whiteSpace: 'nowrap' }}>
        <p className="m-0 mr-4"><i className="bi bi-telephone"></i> +91-9820037956 - Sandeep Doshi (MD),</p>&nbsp;&nbsp;
        <p className="m-0 mr-4"> +91-7900084685 - Harsh Mehta,</p>&nbsp;&nbsp;
        <p className="m-0"> +91-9773630600 - Krishna Madhale</p>
    </div>
    <p style={{marginTop:"10px"}}>GSTIN: 27AAACW8324N1ZU</p>
</div>

                  </div>
                   

                  </div>
              </div>
          {/* </div> */}


   </>
  )
}


 {/* <div className='col mt-1'>
                        <h5>Useful links</h5>
                        <ul style={{ listStyleType: 'none'}}>
                            <li>FAQ For Customer</li>
                            <li>FAQ For Shop</li>
                            <li>Location</li>
                            <li>Contact</li>
                        </ul>
                    </div> */}
                    {/* <div className='col mt-1'>
                        <h5>My Accounts</h5>
                        <ul style={{ listStyleType: 'none'}}>
                            <li>My Account</li>
                            <li>Returns</li>
                            <li>Order History</li>
                            <li>Order Tracking</li>
                        </ul>
                    </div> */}