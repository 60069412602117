import React from 'react'
import './conta.css'

export default function Conta() {
  const onSubmitForm=(event)=>{
    event.preventDefault()
    alert("Messge send sucessfully")
  }
  return (
    <div style={{ backgroundColor: "#eeeded", height: "80%", width: "100%" }}>
      <div className='container ' >
        <h1 style={{ textAlign: "center", fontWeight: "600", fontSize: "3rem", color: "#ea0c71", margin: "0px auto 0" }}>Contact Us</h1>
        <div className='row mb-5 m-3'>
          <h2 style={{ textAlign: "center", color: "black", margin: "0px auto 0", marginBottom: "15px" }}>Any questions or remarks? Just write us a message!</h2>
          <div className='col col-lg-6 col-md-12 col-12 mb-5' style={{ borderRadius: "25px", height: "630px", backgroundColor: "white", marginRight: "20px" }}>
            <img src='https://media.istockphoto.com/id/1459247329/photo/contact-us-or-customer-support-hotline-people-connect-searching-web-browsing-information.jpg?s=612x612&w=0&k=20&c=1KPNsQKrHwp_YhvqYBJwHS05O6KW26ACoGSPFE5Qpf0=' alt='' style={{ height: "400px", width: "92%", margin: "20px", alignItems: "center", borderRadius: "20px", marginTop: "30px" }}></img>

            <div className='col col-lg-4 col-12 col-md-6 d-flex justify-content-evenly' style={{ width: "100%" }}>
              <div>
                <h3>Sydney</h3>
                <h5>45 Pirrama Rd,</h5><h5> Pyrmont NSW</h5>
                <h5>2022</h5>
              </div>
              <div>
                <h3>Melbourne</h3>
                <h5>45 Pirrama Rd,</h5><h5> Pyrmont NSW</h5>
                <h5>2022</h5>
              </div>
              <div>
                <h3>Los Angeles</h3>
                <h5>45 Pirrama Rd,</h5><h5> Pyrmont NSW</h5>
                <h5>2022</h5>
              </div>
            </div>
           {/* <div style={{marginTop:"100px",textAlign:"center"}}> <i style={{}}>Copyright by Mauli Infotech</i></div> */}
          </div>

          {/* second col start here */}

          <div className='col col-lg-5 col-md-12 col-12 mb-5' style={{ borderRadius: "25px", height: "630px", backgroundColor: "white" }}>
            <h1 style={{ textAlign: "center", color: "black", marginTop: "10px", marginBottom: "15px" }}>Get in Touch</h1>
            <h4 style={{ textAlign: "center" }}>Have an inquiry or some feedbak for us? Fill out the form below to contact our team.</h4>
         
            <div class="container" style={{borderRadius:"5px",backgroundColor:"#f2f2f2",padding:"20px"}}>
              <form onSubmit={onSubmitForm}>
                <label for="fname"> Name</label>
                <input type="text" id="fname" name="firstname" placeholder="Your name.." required/>

                  <label for="lname">email</label>
                  <input type="text" id="lname" name="lastname" placeholder="Your email.." />

                   

                    <label for="subject">Subject</label>
                    <textarea id="subject" name="subject" placeholder="Write something.." required style={{height:"100px"}}></textarea>

                    <input type="submit" value="Submit"/>
                    </form>
                  </div>

                </div>

            </div>

          </div>
        </div>
        )
}
