import React from 'react'
import { Link, Outlet } from 'react-router-dom'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser,faNoteSticky } from '@fortawesome/free-solid-svg-icons';
import './NavBar.css'
import Footer from './Footer'
import MenuBar from './MenuBar'

export default function NavBar() {
  return (
    <div><nav className="navbar navbar-expand-lg bg-body-light">
    <div className="container-fluid" style={{marginTop:"0px"}}>
      <a className="navbar-brand" href="#">
        <img
          src="https://pbs.twimg.com/profile_images/1688494686540087296/hfKabbnk_400x400.jpg"
          width="150px"
          alt="Logo"
          className="logo"
          height="150px"
        />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-0 mb-lg-0" style={{ marginRight: "150px" }}>
          <li className="nav-item">
            <Link to="/home" className="nav-link " aria-current="page">
              <b>Home</b>
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link to="/blog" className="nav-link" aria-current="page">
              <b>Blogs</b>
            </Link>
          </li> */}
          <li className="nav-item">
            <Link to="/about" className="nav-link" aria-current="page">
              <b>About Us</b>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/con" className="nav-link" aria-current="page">
              <b>Contact</b>
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link to="/log" className="nav-link" aria-current="page">
              <b>Login</b>
            </Link>
          </li> */}
          <li className="nav-item">
            <Link to="/cart" className="nav-link" aria-current="page">
              <b> <i class="bi bi-cart3"></i>Cart</b>
            </Link>
          </li>

        </ul>
      </div>
    </div>
  </nav>
  {/* <MenuBar/> */}
  <Outlet/>
  <Footer/>
  </div>
  )
}
