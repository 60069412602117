import React from 'react'
import { Link } from 'react-router-dom'

export default function Conta() {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col col-lg-6'>
            <img src='https://media.istockphoto.com/id/1945609448/photo/white-bathroom-modern-japan-minimal-style-3d-rendering.jpg?s=612x612&w=0&k=20&c=q1JlsLO6z9VYPiBFX3XSGbJbtNI_0x_CtnOnEExn6GQ=' alt='' style={{ height: "370px", width: "100%", borderRadius: "10px" }}></img>
          </div>
          <div className='col col-lg-6'>
            <div>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Our Services
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the accordion-body, though the transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Customer Relationship
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Social Networking
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className='container'>
        <div className='row mt-3'>
          <h2 style={{ color: "#ff004f", textAlign: "center" }}>Our Blogs</h2>
          <div className='col col-lg-3'>
            <div class="card" style={{ width: "18rem", borderRadius: "20px" }}>
              <div class="card-body">
                <h5 class="card-title" style={{ color: "#87b9fe" }}><b>Connect with Us</b></h5>
                <p class="card-text" style={{ color: "#7f8c9d", fontFamily: "Poppins", fontSize: "18px" }}>With supporting text below as a natural lead-in to additional content. With supporting text below as a natural lead-in to additional content. With supporting text below as a natural lead-in to additional content.</p>

              </div>
            </div>
          </div>
          <div className='col col-lg-3'>
            <div class="card" style={{ width: "18rem", borderRadius: "20px" }}>
              <div class="card-body">
                <h5 class="card-title" style={{ color: "#87b9fe" }}><b>Feel Relax</b></h5>
                <p class="card-text" style={{ color: "#7f8c9d", fontFamily: "Poppins", fontSize: "18px" }}>With supporting text below as a natural lead-in to additional content. With supporting text below as a natural lead-in to additional content. With supporting text below as a natural lead-in to additional content.</p>

              </div>
            </div>
          </div>
          <div className='col col-lg-3'>
            <div class="card" style={{ width: "18rem", borderRadius: "20px" }}>
              <div class="card-body">
                <h5 class="card-title" style={{ color: "#87b9fe" }}><b>Make Comfortable </b></h5>
                <p class="card-text" style={{ color: "#7f8c9d", fontFamily: "Poppins", fontSize: "18px" }}>With supporting text below as a natural lead-in to additional content. With supporting text below as a natural lead-in to additional content. With supporting text below as a natural lead-in to additional content.</p>

              </div>
            </div>
          </div>

          <div className='col col-lg-3'>
            <div class="card" style={{ width: "18rem", borderRadius: "20px" }}>
              <div class="card-body">
                <h5 class="card-title" style={{ color: "#87b9fe" }}><b>Decorate your Washroom</b></h5>
                <p class="card-text" style={{ color: "#7f8c9d", fontFamily: "Poppins", fontSize: "18px" }}>With supporting text below as a natural lead-in to additional content. With supporting text below as a natural lead-in to additional content. With supporting text below as a natural lead-in to additional content.</p>

              </div>
            </div>
          </div>


        </div>

         <div className='container'>
          <div className='row mt-5'>
            <div className='col col-lg-12 d-flex justify-content-evenly'>
              <Link to={'/fetch'} button type="button" class="btn btn-lg btn-outline-secondary">Whatsapp API</Link>
              <Link to={'/Dyfetch'} button type="button" class="btn btn-lg btn-outline-success">Test Api</Link>
              <Link to={'/Pfetch'} button type="button" class="btn btn-lg btn-outline-danger">OTP Details</Link>
              <Link to={'/fetchLogin'}button type="button" class="btn btn-lg btn-outline-warning">Login Details</Link>
            </div>
          </div>
        </div> 

      </div>
    </>
  )
}
