import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function Fetch() {
    const [data, setData] = useState('');

    useEffect(() => {
        axios.get('http://localhost:5000/proxy/endpoint')
          .then(res => {
            setData(res.data);
          })
          .catch(error => {
            console.error(error);
          });
      }, []);


    return (
        <>
            <div className="App">
                <h1>React and Node.js Integration</h1>
                <p>Message from the server: {JSON.stringify(data, null, 2)}</p>
            </div>
        </>
    )
}



