import React from 'react'
import NavBar from './NavBar'

export default function App() {
  return (
    <div>
        <NavBar/>
    </div>
  )
}
