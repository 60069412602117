import React from 'react';
import './Slide.css'; // Ensure this file has the necessary styles
import BasinCards2 from './BasinCards2';
import { Link } from 'react-router-dom';

export default function WashBasinCard() {
    
  return (
    <>
      <div className='container'>
        <div className='row mt-4 d-flex justify-content-center'> 
        <h3 style={{color:"#ff004f",textAlign:"center"}}>
                Trending Products
                </h3>
          <div className='col mb-2'>
            <div className='card' style={{ width: '22rem', boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active dot-1">
      <img src="https://media.istockphoto.com/id/1073403366/photo/luxurious-minimalist-bathroom-with-slate-black-stone-wall.jpg?s=612x612&w=0&k=20&c=NpgscoGvIlNPyq9ylUWHTaUdC3YXu4hKcT52aCUeNZE=" class="d-block w-100" alt="..." style={{height:"240px"}}/>
    </div>
    <div class="carousel-item ">
      <img src="https://media.istockphoto.com/id/460808359/photo/urban-apartment-bath-counter.jpg?s=612x612&w=0&k=20&c=2ph35H58XyPn7IwY6T84ODsBZtWEN2uL2ckIqHxi1-Q=" class="d-block w-100" alt="..." style={{height:"240px"}}/>
    </div>
    <div class="carousel-item">
      <img src="https://media.istockphoto.com/id/1158574749/photo/modern-bathroom.jpg?s=612x612&w=0&k=20&c=uFgkC7dkhEcAt4fp0Z2yrgxlib0sHg2mfgIctwtq-9s=" class="d-block w-100" alt="..." style={{height:"240px"}}/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next  " type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
              <div className="card-body">
               <h3 style={{ fontFamily: "Serif",color:"#ff004f"}}>Wall-Mounted</h3>
               <p  style={{ fontFamily: "Serif",color:"#FF5733",fontWeight:"600",fontSize:"20px"}}>Series:Kubix Prime</p>
               <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px",color:"#1593af"}}>Product Code: ARC-87213B</p>
              
                <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px"}}><b>Product: </b>Single Lever Exposed Parts Kit of Hi-flow Diverter Consisting of Operating Lever</p>
                <p class="card-text" style={{color:"#b31c6e",margin:"0",padding:"0" ,fontSize:"20px",fontWeight:"600"}}>Price:<i class="bi bi-currency-rupee"></i>19,125</p>
                
                <Link to={'/Basindet'} class="btn btn-outline-warning"style={{marginTop:"5px"}}>View Product Detail</Link>
              </div>
            </div>
          </div>             {/* col div end here*/}

          <div className='col mb-2'>
            <div className='card' style={{ width: '22rem', boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
            <div id="carouselExampleControls2" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active dot-1">
      <img src="https://media.istockphoto.com/id/499914049/photo/bathroom-sink-counter-towels-water-glass-blue.jpg?s=612x612&w=0&k=20&c=N0g1YhSi90TRqW9jJ1_feUWgCgeaFGyQnKT_dNvyANs=" class="d-block w-100" alt="..." style={{height:"240px"}}/>
    </div>
    <div class="carousel-item ">
      <img src="https://media.istockphoto.com/id/1396585161/photo/wash-basin-in-interior-of-bathroom.jpg?s=612x612&w=0&k=20&c=1TPlHzi7wxnRgXWDFbzyb6tkFke2kt2tdhXqsquoaGI=" class="d-block w-100" alt="..." style={{height:"240px"}}/>
    </div>
    <div class="carousel-item">
      <img src="https://media.istockphoto.com/id/1351349614/photo/sink-bowl-with-faucet-for-water-in-living-room-with-green-plants-in-sunlight-sweet-cozy.jpg?s=612x612&w=0&k=20&c=5QQ15B_ZfhhwKXt6sbByEYYht_kzLT1LyvHRA07itp4=" class="d-block w-100" alt="..." style={{height:"240px"}}/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next  " type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
              <div className="card-body">
               <h3 style={{ fontFamily: "Serif",color:"#ff004f"}}>Corner Basins</h3>
               <p  style={{ fontFamily: "Serif",color:"#FF5733",fontWeight:"600",fontSize:"20px"}}>Series:Vignette Prime</p>
               <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px",color:"#1593af"}}>Product Code: DRC-37083K</p>

                <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px"}}> <b>Product: </b>Exposed Part Kit of Single Lever Basin Mixer Wall Mounted Consisting stick and screw fitting.</p>
                <p class="card-text" style={{color:"#b31c6e",margin:"0",padding:"0" ,fontSize:"20px",fontWeight:"600"}}>Price:<i class="bi bi-currency-rupee"></i>14,550</p>
                
                <Link to={'/Basindet'} class="btn btn-outline-warning" style={{marginTop:"5px"}}>View Product Detail</Link>
              </div>
            </div>
          </div>             {/* col div end here*/}

          <div className='col '>
            <div className='card' style={{ width: '22rem', boxShadow: "5px 5px 10px gray", border: "1px solid white", padding: "5px" }}>
            <div id="carouselExampleInterval3" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active dot-1">
      <img src="https://media.istockphoto.com/id/1297203369/photo/public-toilet-interior.jpg?s=612x612&w=0&k=20&c=1gSe4pFOfbDYwoHIXWatD8QmgSIGAxLEfFRbsMBEhTc=" class="d-block w-100" alt="..." style={{height:"240px"}}/>
    </div>
    <div class="carousel-item ">
      <img src="https://media.istockphoto.com/id/611099698/photo/modern-bathroom.jpg?s=612x612&w=0&k=20&c=5qTF1bZA1y21An0DaW6Uun6lmFMu_qTEY3DKFL5gQEQ=" class="d-block w-100" alt="..." style={{height:"240px"}}/>
    </div>
    <div class="carousel-item">
      <img src="https://media.istockphoto.com/id/91156679/photo/close-up-of-modern-kitchen-faucet-and-sink.jpg?s=612x612&w=0&k=20&c=vDCYM2Nnx3nkp7NOv0xXsY1rtZJ_6ZbI0Ddf5bxL4ak=" class="d-block w-100" alt="..." style={{height:"240px"}}/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval3" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next  " type="button" data-bs-target="#carouselExampleInterval3" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
              <div className="card-body">
               <h3 style={{ fontFamily: "Serif",color:"#ff004f"}}>Disc Basin</h3>
               <p  style={{ fontFamily: "Serif",color:"#FF5733",fontWeight:"600",fontSize:"20px"}}>Series:Continental</p>
               <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px",color:"#1593af"}}>Product Code: KUP-35233NKPM</p>
                <p className="card-text" style={{margin:"0",padding:"0",marginBottom:"2px"}}> <b>Product: </b>Exposed Part Kit of Concealed Stop Cock & Flush Cock with Fitting Sleeve Enlarged.</p>
                <p class="card-text" style={{color:"#b31c6e",margin:"0",padding:"0" ,fontSize:"20px",fontWeight:"600"}}>Price:<i class="bi bi-currency-rupee"></i>9,799</p>
                <Link to={'/Basindet'} class="btn btn-outline-warning" style={{marginTop:"5px"}}>View Product Detail</Link>
              </div>
            </div>
          </div>             {/* col div end here*/}
        </div>             {/* row div end here*/}
      </div>           {/* row div end here*/}


      <BasinCards2/>
    </>
  );
}
