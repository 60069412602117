import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

export default function Log() {
  const [formData, setFormData] = useState({
    username: '',
    pass: ''
  });
  const [record, setRecord] = useState(null);
   const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState({
    username: '',
    pass: ''
  });

  useEffect(() => {
    axios.post('http://localhost:5000/proxy/Loginpoint', {
        one: 'Mau0001',
        two: 'MI#Orfo$oo7',
        authApiKey: 'ytfkenaojjawmbjnbsyyj-vhvbs'
    })
    .then(res => {
        setRecord(res.data);
        setLoading(false);
       
    })
    .catch(error => {
        console.error('There was an error!', error);
        setLoading(false);
    });
}, []);

  const navigate = useNavigate();

  // Validate username as either mobile number or email
  const validateUsername = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileNoPattern = /^[0-9]{10}$/; // 10-digit number
    if (emailPattern.test(value)) {
      return ''; // Valid email
    } else if (mobileNoPattern.test(value)) {
      return ''; // Valid mobile number
    }
    return 'Username must be a valid mobile number (10 digits) or email address.';
  };

  // Validate password with at least one alphabet, one number, and one special character
  const validatePassword = (value) => {
    const passwordPattern = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (passwordPattern.test(value)) {
      return ''; // Valid password
    }
    return 'Include at least one letter, number, special character.';
  };

  // Handle form input changes
  const captureForm = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });

    // Validate the specific field
    if (name === 'username') {
      setErrors({
        ...errors,
        username: validateUsername(value)
      });
    } else if (name === 'pass') {
      setErrors({
        ...errors,
        pass: validatePassword(value)
      });
    }
  };

  // Handle form submission
  const SubmitForm = (event) => {
    event.preventDefault();

    // Validate all fields before submission
    const usernameError = validateUsername(formData.username);
    const passwordError = validatePassword(formData.pass);

    if (usernameError || passwordError) {
      setErrors({
        username: usernameError,
        pass: passwordError
      });
      return;
    }

    // If validation passes, perform form submission logic
    console.log(formData);
    alert("Login successful");

    // Reset form data
    setFormData({
      username: '',
      pass: ''
    });

    // Clear all errors
    setErrors({
      username: '',
      pass: ''
    });

    // Navigate to the home page
    navigate('/home');
  };

  const handleRegister = () => {
    // Add navigation or logic here
    //alert('Navigate to registration page');
  };

  return (
    <div className='container'style={{marginLeft:"50px"}}>
      <div className='row mt-1'>
        <div className='col d-flex justify-content-center' style={{ alignContent: "center", alignItems: "center" }}>
          <div style={{ border: "3px solid #f1f1f1", boxShadow: "10px 10px 10px #c8c8c8", borderRadius: "10px", width: "28rem", marginTop: "5px", padding: "20px" }}>
            <h1 className='text-center' style={{ color: "#21abd3", fontWeight: "700", fontSize: "2.95rem" }}>Login</h1>
            <br />
            {/* <img
              alt='avatar'
              src='https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1724630400&semt=ais_hybrid'
              style={{ borderRadius: "50%", height: "120px", display: "block", width: "120px", marginLeft: "auto", marginRight: "auto" }}
            /> */}
            <div style={{ margin: "15px" }}>
              <form onSubmit={SubmitForm}>
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">Username <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={captureForm}
                    value={formData.username}
                    name="username"
                    id="username"
                    aria-describedby="usernameHelp"
                    placeholder="Enter your Mobile no./Email Id"
                    required
                  />
                  {errors.username && <div className="text-danger"><p style={{ marginBottom: "0px", marginTop: "0px", fontSize: "0.875em" }}>{errors.username}</p></div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">Password <span className="text-danger">*</span></label>
                  <input
                    type="password"
                    onChange={captureForm}
                    name='pass'
                    value={formData.pass}
                    className="form-control"
                    placeholder='Your Password'
                    id="password"
                    required
                  />
                  {errors.pass && <div className="text-danger"><p style={{ marginBottom: "0px", marginTop: "0px", fontSize: "0.875em" }}>{errors.pass}</p></div>}
                </div>
                <div style={{display:"flex", justifyContent:"center",marginTop:"30px"}}>
                <button type="submit" className="btn btn-primary mb-1"style={{ marginRight: '10px' }}>Submit</button>
                <Link to={'/register'} button type="button" onClick={handleRegister} className="btn btn-primary mb-1"style={{ marginRight: '10px' }}>Register now</Link>
                {/* <Link to={'/register'} className="btn btn-warning"  onClick={handleRegister}>
                  Register now
                </Link> */}
                </div>
                <br />
                <a href='/forgot-password' style={{ marginTop: '20px', display: 'inline-block' }}>Forgot Password?</a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Link, useNavigate } from 'react-router-dom';

// export default function Log() {
//   const [formData, setFormData] = useState({
//     username: '',
//     pass: ''
//   });
//   const [record, setRecord] = useState(null);
//    const [loading, setLoading] = useState(true)
//   const [errors, setErrors] = useState({
//     username: '',
//     pass: ''
//   });

//   useEffect(() => {
//     axios.post('http://localhost:5000/proxy/Loginpoint', {
//         one: 'Mau0001',
//         two: 'MI#Orfo$oo7',
//         authApiKey: 'ytfkenaojjawmbjnbsyyj-vhvbs'
//     })
//     .then(res => {
//         setRecord(res.data);
//         setLoading(false);
       
//     })
//     .catch(error => {
//         console.error('There was an error!', error);
//         setLoading(false);
//     });
// }, []);

//   const navigate = useNavigate();

//   // Validate username as either mobile number or email
//   const validateUsername = (value) => {
//     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     const mobileNoPattern = /^[0-9]{10}$/; // 10-digit number
//     if (emailPattern.test(value)) {
//       return ''; // Valid email
//     } else if (mobileNoPattern.test(value)) {
//       return ''; // Valid mobile number
//     }
//     return 'Username must be a valid mobile number (10 digits) or email address.';
//   };

//   // Validate password with at least one alphabet, one number, and one special character
//   const validatePassword = (value) => {
//     const passwordPattern = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
//     if (passwordPattern.test(value)) {
//       return ''; // Valid password
//     }
//     return 'Include at least one letter, number, special character.';
//   };

//   // Handle form input changes
//   const captureForm = (event) => {
//     const { name, value } = event.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });

//     // Validate the specific field
//     if (name === 'username') {
//       setErrors({
//         ...errors,
//         username: validateUsername(value)
//       });
//     } else if (name === 'pass') {
//       setErrors({
//         ...errors,
//         pass: validatePassword(value)
//       });
//     }
//   };

//   // Handle form submission
//   const SubmitForm = (event) => {
//     event.preventDefault();

//     // Validate all fields before submission
//     const usernameError = validateUsername(formData.username);
//     const passwordError = validatePassword(formData.pass);

//     if (usernameError || passwordError) {
//       setErrors({
//         username: usernameError,
//         pass: passwordError
//       });
//       return;
//     }

//     // If validation passes, perform form submission logic
//     console.log(formData);
//     alert("Login successful");

//     // Reset form data
//     setFormData({
//       username: '',
//       pass: ''
//     });

//     // Clear all errors
//     setErrors({
//       username: '',
//       pass: ''
//     });

//     // Navigate to the home page
//     navigate('/home');
//   };

//   const handleRegister = () => {
//     // Add navigation or logic here
//     //alert('Navigate to registration page');
//   };

//   return (
//     <div className='container'style={{marginLeft:"50px"}}>
//       <div className='row mt-1'>
//         <div className='col d-flex justify-content-center' style={{ alignContent: "center", alignItems: "center" }}>
//           <div style={{ border: "3px solid #f1f1f1", boxShadow: "10px 10px 10px #c8c8c8", borderRadius: "10px", width: "28rem", marginTop: "5px", padding: "20px" }}>
//             <h1 className='text-center' style={{ color: "#21abd3", fontWeight: "700", fontSize: "2.95rem" }}>Login</h1>
//             <br />
//             {/* <img
//               alt='avatar'
//               src='https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1724630400&semt=ais_hybrid'
//               style={{ borderRadius: "50%", height: "120px", display: "block", width: "120px", marginLeft: "auto", marginRight: "auto" }}
//             /> */}
//             <div style={{ margin: "15px" }}>
//               <form onSubmit={SubmitForm}>
//                 <div className="mb-3">
//                   <label htmlFor="username" className="form-label">Username <span className="text-danger">*</span></label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     onChange={captureForm}
//                     value={formData.username}
//                     name="username"
//                     id="username"
//                     aria-describedby="usernameHelp"
//                     placeholder="Enter your Mobile no./Email Id"
//                     required
//                   />
//                   {errors.username && <div className="text-danger"><p style={{ marginBottom: "0px", marginTop: "0px", fontSize: "0.875em" }}>{errors.username}</p></div>}
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="password" className="form-label">Password <span className="text-danger">*</span></label>
//                   <input
//                     type="password"
//                     onChange={captureForm}
//                     name='pass'
//                     value={formData.pass}
//                     className="form-control"
//                     placeholder='Your Password'
//                     id="password"
//                     required
//                   />
//                   {errors.pass && <div className="text-danger"><p style={{ marginBottom: "0px", marginTop: "0px", fontSize: "0.875em" }}>{errors.pass}</p></div>}
//                 </div>
//                 <div style={{display:"flex", justifyContent:"center",marginTop:"30px"}}>
//                 <button type="submit" className="btn btn-primary mb-1"style={{ marginRight: '10px' }}>Submit</button>
//                 <Link to={'/register'} button type="button" onClick={handleRegister} className="btn btn-primary mb-1"style={{ marginRight: '10px' }}>Register now</Link>
//                 {/* <Link to={'/register'} className="btn btn-warning"  onClick={handleRegister}>
//                   Register now
//                 </Link> */}
//                 </div>
//                 <br />
//                 <a href='/forgot-password' style={{ marginTop: '20px', display: 'inline-block' }}>Forgot Password?</a>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
