import React from 'react'
import MenuBar from './MenuBar'
import SlideCards from './SlideCards'
import Slider from './Slider'
import TapSlider from './TapSlider'
import JaquarInfo from './JaquarInfo'

export default function Jaquar() {
  return (
    <div>
        <MenuBar/>
        <JaquarInfo/>
       {/* <TapSlider/> */}

    </div>
  )
}
