import React from 'react'

export default function InfoCard() {
  return (
    <>
    <div className='container'>
    <div className='row mt-4 d-flex justify-content-center'>
        <div className='col col-12 col-md-8 col-lg-5 '>
            <img 
                src='https://media.istockphoto.com/id/1221362919/photo/white-bathroom-with-bath-and-large-window.jpg?s=612x612&w=0&k=20&c=r7TzhDH5UZ4gLhC6QopMbUVSz1fEPAiDfg5uQwsiCNc=' 
                style={{borderRadius:"10px", width:"100%", height:"auto"}}
                alt="Bathroom"
            />
        </div>
        <div className='col-12 col-md-0 col-lg-2'>
            {/* You can put content here if needed */}
        </div>
        <div className='col-12 col-md-8 col-lg-5'>
            <h3 style={{color:"#ff004f"}}>
                SEO Friendly
            </h3>
            <p style={{color:"gray", fontFamily:"Poppins"}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam pulvinar luctus sem, eget porta orci. Maecenas molestie dui id diam feugiat, eu tincidunt mauris aliquam. Duis commodo vitae ligula et interdum. Maecenas faucibus mattis imperdiet. In rhoncus ac ligula id ultricies.
            </p>
        </div>
    </div>
</div>


    <div className='container'>
    <div className='row mt-3'>
        <div className='col-lg-4 mb-2'>
        <img src='https://media.istockphoto.com/id/1063241040/photo/modern-contemporary-bathroom-with-nature-view-3d-render.jpg?s=612x612&w=0&k=20&c=ZjH6NIiefN-PLKcOvJfFgiBEMvYU2JAmKBzSGu3SEEU=' style={{borderRadius:"10px" ,width:"100%" ,height:"250px"}}></img>

        </div>

        <div className='col-lg-4 mb-2'>
        <img src='https://media.istockphoto.com/id/1308282338/photo/modern-bathroom-interior-stock-photo.jpg?s=612x612&w=0&k=20&c=r6qXSudX7P7YOzYsuHsQ_6pjAekJOF4XnEpdrtfqmsg=' style={{borderRadius:"10px" ,width:"100%" ,height:"250px"}}></img>

        </div>

        <div className='col-lg-4'>
        <img src='https://media.istockphoto.com/id/1439692133/photo/white-round-side-table-in-modern-and-luxury-design-bathroom-and-tropical-banana-tree-with.jpg?s=612x612&w=0&k=20&c=2ZsJUvf-bHBCrP8F6GiRhhvoM8dAiw3kBB9TxBigeao=' style={{borderRadius:"10px" ,width:"100%",height:"250px"}}></img>

        </div>

        </div>      {/*  row div end here */}
    </div>       {/*  container div end here */}
    </>
  )
}
