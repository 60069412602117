import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function PostFetch() {
    const [infoo, setInfoo] = useState(null); // Default to null to handle no data
    const [loading, setLoading] = useState(true); // Track loading state

    useEffect(() => {
        axios.post('http://localhost:5000/proxy/postpoint', {
            one: '7775800742',
            two: 'kanchanghule1718@gmail.com',
            authApiKey: 'ytfkenaojjawmbjnbsyyj-vhvbs'
        })
        .then(res => {
            setInfoo(res.data);
            setLoading(false);
        })
        .catch(error => {
            console.error('There was an error!', error);
            setLoading(false);
        });
    }, []);

    return (
        <div className="App container mt-4">
            <h1 className="text-center mb-4">React and Node.js Integration</h1>
            <p className="text-center">
                Message from the server: {loading ? 'Loading...' : infoo ? JSON.stringify(infoo, null, 2) : 'No data available'}
            </p>

        </div>
    );
}
